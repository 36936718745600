/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-member-access */

import { camelCase, isArray, isEmpty, isNil, isNumber, isString, mapKeys, snakeCase } from 'lodash-es';
import { parsePhoneNumber } from 'libphonenumber-js';

import { FiatCurrency, FiatCurrencyCode } from '@bp/shared/models/currencies';
import { Stringify } from '@bp/shared/typings';
import { Countries, Country, CountryCode } from '@bp/shared/models/countries';
import { Validators } from '@bp/shared/features/validation/models';

import { environment } from '@bp/frontend/environments';
import { CheckoutValidators } from '@bp/frontend/domains/checkout/validation';
import { CheckoutLanguage, CheckoutLanguages, CheckoutTheme, AlwaysVisibleInputsForProvidersScheme } from '@bp/frontend/domains/checkout/core';
import { PaymentOptionType } from '@bp/frontend/models/business';

import { chain } from '@bp/checkout-frontend/utilities';

import { ValidatableProperty } from './validatable-property';
import { MOCK_SESSION_KEYS } from './mock-sessions-keys';

export type EmbeddedDataDTO = Partial<Stringify<EmbeddedData>>;

export class EmbeddedData {

	firstName = new ValidatableProperty<string | undefined>([
		Validators.required,
		Validators.noSpecialCharactersOrDigits,
	]);

	lastName = new ValidatableProperty<string | undefined>([
		Validators.required,
		Validators.noSpecialCharactersOrDigits,
	]);

	phone = new ValidatableProperty<string | undefined>(
		[ Validators.required ],
		[ CheckoutValidators.phone ],
	);

	address = new ValidatableProperty<string | undefined>([
		Validators.required,
	]);

	email = new ValidatableProperty<string | undefined>([
		Validators.required,
		Validators.email(),
	]);

	city = new ValidatableProperty([
		Validators.required,
		Validators.onlyLettersAndHyphens,
	]);

	countryCode = new ValidatableProperty<CountryCode>([
		Validators.required,
		CheckoutValidators.countryCode,
	]);

	stateCode: ValidatableProperty;

	zipCode: ValidatableProperty;

	amount = new ValidatableProperty([ Validators.required, CheckoutValidators.amount ]);

	cardHolderName = new ValidatableProperty([ Validators.required ]);

	amountLock!: boolean;

	checkoutKey?: string;

	checkoutToken!: string;

	cashierKey?: string;

	cashierToken!: string;

	cid?: string;

	token?: string;

	cashierSessionId?: string;

	checkoutSessionId!: string;

	sessionId?: string;

	language: CheckoutLanguage | null;

	hideLanguagesDropdown!: boolean;

	orderId!: string;

	platformId?: string;

	trackingId?: string;

	affiliateId?: string;

	ip?: string;

	birthDate?: number;

	/**
	 * Shortcut to a payment method with ability to return to the available methods
	 */
	directPaymentMethod?: PaymentOptionType;

	/**
	 * Show the providers only of this payment method type
	 */
	singlePaymentMethod?: PaymentOptionType;

	/**
	 * Jump to the provider screen without ability to return to the available methods
	 */
	singlePaymentProvider?: string;

	/**
	 * Hide the providers only of this payment method types
	 */
	hidePaymentMethods?: PaymentOptionType[];

	/**
	 * Hide providers
	 */
	hidePaymentProviders?: string[];

	onlyCreditCardPaymentMethod!: boolean;

	paymentProvider?: string;

	alwaysVisibleInputsForProviders: AlwaysVisibleInputsForProvidersScheme = {};

	depositButtonText!: string;

	hideUi!: boolean;

	currencyLock!: boolean;

	currencyCode?: FiatCurrencyCode;

	currency!: FiatCurrency;

	country?: Country;

	buttonMode?: 'modal' | 'spot' | 'tab';

	theme!: CheckoutTheme;

	/**
	 * deprecated
	 */
	showRedirectMessage!: boolean;

	/**
	 * Instead of approved or declined page
	 */
	showRedirectMessageOnFinalStatusPage: boolean;

	/**
	 * Instead of approved or declined page
	 */
	showProcessingMessageOnFinalStatusPage!: boolean;

	dontSkipSinglePaymentBox!: boolean;

	validateInputsOnFocusOut!: boolean;

	disableProcessingMessages!: boolean;

	payMode = false;

	tickSaveCreditCardCheckboxByDefault = false;

	hideSaveCreditCardCheckbox = false;

	enableFilterByCardBrand = false;

	showPlaceholderForCardNumber = false;

	dontRenderCardHolderNameInUppercase = false;

	hideCardHolderNameWhenFullNameIsAvailable = true;

	showPspPaymentOptionsAsDropdown = false;

	showPlaceholderForCvv = false;

	// internal cashier props
	isMerchantAdmin = false;

	isDemostand = false;

	preventOverrideBySession = false;

	user?: string;

	presetId?: string;

	creditCard?: {
		number: number;
		expireMonth: number;
		expireYear: number;
		cvv: number;
	};

	backUrl?: string;

	useLocalBackend?: boolean;

	useMockBackend?: boolean;

	paymentToken?: string;

	phoneCountryDialCode?: string;

	get fullName() {
		if (this.firstName.invalid && this.lastName.invalid)
			return '';

		let name = this.firstName.value;

		name += this.lastName.valid ? ` ${ this.lastName.value }` : '';

		return name?.trim();
	}

	get isCFDGlobe() {
		return this.checkoutKey === '040678cb-8260-4d01-91c7-996bcd5b5818';
	}

	get isEquitiJordanAndUAE() {
		return [
			'1afb4b49-325f-45ee-a677-4335f0464aea',
			'21d90027-6b8f-4c82-8c17-96757455dc50',
			'5dd6f7df-1ef9-4753-896b-8feadfe8419c',
			'ceafd775-3455-49a8-8b57-0c4f328314c8',
			'0381618d-c255-4b82-b697-f0f2a7b9d52b',
			'f019a8d3-9cec-429d-ae72-3cea5cd362ce',
			'3e48c911-a638-48ac-847e-b6cad3e6e5f9',
		]
			.includes(this.checkoutKey!);
	}

	get isEquitiUAE() {
		return [
			'f9213889-abb7-4337-a6e7-60d1b2bed109',
			'3e48c911-a638-48ac-847e-b6cad3e6e5f9',
		]
			.includes(this.checkoutKey!);
	}

	get isEquitiSeychelles() {
		return [
			'4669679a-6e5c-41b0-b4fd-70a4b0e8a430',
			'8c490c93-6880-46b1-b74d-8bd7966c3348',
			'79a28382-1111-4346-83b4-f025979a91db',
			'f3cea285-dd38-4dd6-a2d3-02fd6e1eb304',
			'a7f6a1b9-4777-49e4-9df3-e12e7f17aa16',
		]
			.includes(this.checkoutKey!);
	}

	get isEquitiFxPesa() {
		return [
			'49c8e846-9da8-4370-9b33-8686dd6ad724',
			'78006bb9-b69e-4f65-848a-2f2041d4c0e9',
			'0401a026-7415-4975-9c8d-2730481be9d4',
		]
			.includes(this.checkoutKey!);
	}

	get isEquitiEGM() {
		return [
			'91452783-41b0-4efb-8668-66449fb2a9cb',
			'6d091721-c471-4410-8633-b4043752cc91',
			'c25a9ca3-d24e-4a4d-9225-4da0aa0974b4',
			'068fc757-44c6-4aee-868d-68815c91cd61',
			'f4ae089e-2311-4950-994d-1a1eb12b3d29',
			'7473efed-b744-4830-95dc-473181de91bb',
		]
			.includes(this.checkoutKey!);
	}

	get isCentury() {
		return [
			'48047bf4-a928-49d6-aa50-3e615d3e443d',
			'6441256d-a708-4437-82d9-0dfbd9ff683a',
			'291d370c-d59f-4332-b468-a8dfe73fc977',
		]
			.includes(this.checkoutKey!);
	}

	get isRaxtrade() {
		return this.checkoutKey === 'c402e2a5-5675-43a2-9d06-8c624d01ff47';
	}

	get isDemoPreset() {
		return this.presetId === '9cxxLT8NiClZ0lBtEJrk';
	}

	get isVantageFX() {
		return this.checkoutKey === '05753962-8cc7-4df7-adec-c42cf60a5a13';
	}

	get isLifacts() {
		return this.checkoutKey === 'c9eb2f2f-f07a-4a65-8b02-35ff611537ac';
	}

	get isHighlow() {
		return [ '4d37047d-d1ca-476b-b8cd-dffb598d191e', '46b8bdb4-5f49-49bd-8b87-8694a79ff08b', '05c68d13-2796-4e1c-9083-2675e1e5ff17' ]
			.includes(this.checkoutKey!);
	}

	get isHighlowV2() {
		return [
			MOCK_SESSION_KEYS.highlowV2,
			'fd6fddf7-80cc-4a3b-90d5-7e3f27701164',
			'1f91de3e-6d7f-4508-80c2-0db189cb4ba6',
			'7bfaece8-6b7f-4787-aecd-75cba918c99c',
			'fdc1a00b-4948-4621-8807-a10949b69e86',
			'cae6d5e4-1e43-4cf6-9547-79fd83bbc2d6',
			'13d792d1-6549-47df-b207-65c1db00ec70',
			'9dbbfde0-65fd-4765-b6b8-1e76edd6a3f0',
			'08699f3f-e104-4064-b5af-851e3e76aa45',
			'579d2f15-8a7e-4a86-9dda-aac918f56f7c',
			'219fd85a-e056-494f-96b9-abb760d3d1fa',
			'0d9ff5ea-1a02-4b28-9d89-e1e40856817e',
		].includes(this.checkoutKey!);
	}

	get isHighlowQoneco() {
		return [
			MOCK_SESSION_KEYS.highlowQoneco,
			'085e08e6-8802-4bdd-9aaa-fa2b5b3ab924',
			'2dd690be-482b-4a29-a70a-5eabcaff34bc',
			'dcf16f0c-2d3e-4fab-b80a-f9932720e911',
			'ceca1956-26d2-432c-a860-8d22d6d858a5',
			'7a68cb73-e7fc-4455-ae05-c4655a7a9e65',
			'dcccc7a0-9376-429e-9874-e5d44218dc57',
			'0968a64d-4616-45f0-a89a-80d9f7c92b4e',
			'c631d8fe-5c60-4578-aee4-22f2c0970c60',
			'8e053ca8-d853-45e0-acac-0dd3cea8045d',
		]
			.includes(this.checkoutKey!);
	}

	get isHighlowXoro() {
		return [
			MOCK_SESSION_KEYS.highlowXoro,
			'1da6b426-1be1-473f-b643-42170725bd05',
			'a88ed00e-e8db-4bf6-a600-9f877c80a254',
			'47237e6a-aa2d-41bd-918d-590b7b84bb44',
		].includes(this.checkoutKey!);
	}

	get isMayfair() {
		return this.checkoutKey === 'b747d17d-d05f-4fb4-8150-29a7061c0cf7';
	}

	get isEtihad() {
		return this.checkoutKey === '69e85f40-2b20-4da4-bef2-c74a85263d74';
	}

	get isLegacyFx() {
		return this.checkoutKey === '3ba506f2-0508-4d48-a4dc-365eb0b26b92';
	}

	get isFortrade() {
		return this.checkoutKey === 'f6a99271-ffc1-4cea-9a25-593af1351a10';
	}

	get isFocusOption() {
		return this.checkoutKey === '6b2923d9-ed8a-44ac-afc6-af7d4678857d';
	}

	get isEzInvest() {
		return this.checkoutKey === '2d2ad389-1657-4f46-9168-00f782edaca9';
	}

	get isJamesAllen() {
		return [
			'54f3f418-5a37-4b2c-bdb6-37640cdfc0a7',
			'9d6f193c-fb31-4a2f-a982-a2d891db2415',
			/* v2 */ '7485528d-1a3d-4381-86bf-10fc52525aee',
			/* v2 */ '991adcbe-0a1d-4120-82f6-b350ee2cedf4',
			/* v2 */ 'ddca4f09-ffb8-4b17-82a7-5b77855a3df3',
		].includes(this.checkoutKey!);
	}

	get isJamesAllenBluennile() {
		return [ '97f53e8d-d974-497d-a66c-4dc3cca7138e', 'aabad00a-45be-4f9e-8e54-f0c1cb1f8697' ].includes(this.checkoutKey!);
	}

	get isBitlevex() {
		return this.checkoutKey === 'b8cfda64-13c8-4f55-a4b1-bc9c6ff4a849';
	}

	get isGFMarkets() {
		return [ '8df7c7c5-2a89-4aa4-94ac-38f3bf585ca6', 'b640ccaa-2d8e-4303-9e48-f75c6de93c7f', 'ae867001-6dba-4d6e-a2ab-f803bc0bb81f' ].includes(this.checkoutKey!);
	}

	get isIQuoto() {
		return this.checkoutKey === '4b5442e7-bf81-4e6b-a179-776fe9673a85';
	}

	get isSaxoDidge() {
		return [ 'bde6047b-3e09-444d-8412-2f0c4525c4c1', 'dea119e8-0516-47f2-abc9-c1bbea37d573' ]
			.includes(this.checkoutKey!);
	}

	get isAdvanceMarket() {
		return [ 'a9aec2b2-5c2d-4437-a3a2-f39c86a6825b', '48cbd11e-afd5-4a49-adf7-b44e53f44c6c' ]
			.includes(this.checkoutKey!);
	}

	get isSodaCell() {
		return this.checkoutKey === '617150bb-e2f8-4abd-af38-da164ef296c5';
	}

	get isColmex() {
		return [ '5b768bf6-72f5-498d-91a7-b4f19b620770', 'ba126ba8-2aef-4955-9f5d-16cd2bee9fc2', '66bb1cd9-9e0d-48a5-adcc-095fbfa39f7b' ]
			.includes(this.checkoutKey!);
	}

	get isCalypsa() {
		return [ '43458633-e1a9-4353-8361-fb897fe96226', '787255b7-2a00-4103-b821-c6c4754e0ebc', '56222cfe-ce7e-4a76-abc7-d5a244de3d4b' ]
			.includes(this.checkoutKey!);
	}

	get isExinity() {
		return [ '465cfee5-122a-47e0-9de9-129233332a1c', '6fa9c8b8-e07a-4903-8cd4-d38c17a91c84' ].includes(this.checkoutKey!);
	}

	get isDavidShields() {
		return [
			'3fe887ba-bdc6-40cf-a75e-21611bb17329',
			'bbed6a26-1e8f-4ad3-879b-9dbf83b0700a',
			'e796ea09-ff63-4a26-8ffa-dc6a42bb5f08',
			'6491d225-a7f7-4836-9645-2df6ca9795be',
			'dcca6365-73b3-4281-81ba-ec93b6c88ab1',
			'fa75b941-843f-4fcd-896c-a261b73c157d',
			'd0a8e83a-ca8d-4706-af0e-1a19c141bbb8',
			'16f6ee0e-6087-4717-b718-35afd7e53a56',
			'e5bdef81-36c4-41fb-9b1a-e0ec160d8606',
			'2d3c63af-8bc1-4e60-bc80-1c097cbcae38',
		].includes(this.checkoutKey!);
	}

	get isExcentCapital() {
		return [
			'984e3e9a-16d2-4960-9d61-2936b202fa85',
		]
			.includes(this.checkoutKey!);
	}

	get isTradeQuo() {
		return [
			'28befd1b-4aa1-4027-b40d-e81ae762a27b',
			'c6c1bc56-2aaf-4bcd-bd78-426dcae1b29e',
			'27f278f9-275b-42a3-8e51-581f461ae404',
			'48878679-98c6-4022-a74d-2bf3721573d2',
			'606b3b92-4cf7-4d68-9579-5635ed9b85e9',
			'07531850-fa14-439f-9033-f0ac8956d9ec',
			'b954cd6b-cd7e-4cd8-be84-a89ea81ddf17',
			'd52432f1-0d68-4744-9950-a8c6a03c5ac7',
			'18830340-54d0-4e96-9256-555e792be844',
			'e7bffb3e-fa2e-469c-82cd-b8caa1197ba3',
			'ec4de07a-e02e-4b47-99e2-03c8e1b50496',
		]
			.includes(this.checkoutKey!);
	}

	get isSecurePharma() {
		return [
			MOCK_SESSION_KEYS.securePharma,
			'7723b88c-f443-4b1e-8940-3c42f5c5ab8e',
		]
			.includes(this.checkoutKey!);
	}

	get isSimplexLeonardo() {
		return [
			'e1748efb-07ef-4b74-971e-ed533866d0b3',
			'f59a620f-7856-4311-9f3b-8a20ca7e78c0',
			'e1748efb-07ef-4b74-971e-ed533866d0b3',
		]
			.includes(this.checkoutKey!);
	}

	get isHarvestRiskSolutions() {
		return [
			'b7af95c1-2f5b-4b4d-b8f4-cf7a33ae6c7e',
			'aba38f46-86cb-4338-ac71-834443b41838',
			'43fdefc4-7934-49d7-b986-4d667a703601',
		]
			.includes(this.checkoutKey!);
	}

	get isCallistoVivezAuCanada() {
		return [
			'34511ce4-6155-4ed1-9038-f4687bf109e2',
			'fa117e96-2808-4ee7-9a9e-375ad5f8844a',
		]
			.includes(this.checkoutKey!);
	}

	get isCallistoRelocation2canada() {
		return [
			'31ff47c7-a43d-4a2a-ab3e-cdf889a06493',
			'1a312964-e554-43d9-9af4-866be12cb1ae',
		]
			.includes(this.checkoutKey!);
	}

	get isCallistoCanadaCIS() {
		return [
			'400dc5eb-a0cf-44c6-b672-ba7f8285ad45',
			'e32e5108-0f4a-4385-9beb-8cdeb555c093',
		]
			.includes(this.checkoutKey!);
	}

	get isMalfex() {
		return [
			'948d48a4-8d96-4305-904c-d1cccd936fe2',
			'67abc378-e74b-42ce-b5e7-c404fc67fb93',
		].includes(this.checkoutKey!);
	}

	get isPUprimeVTmarkets() {
		return [
			'71039f3d-8205-4d45-9663-ff0516f9a260',
			'36e4de4e-66b6-4b75-beb9-37f44966a926',
			'362105a3-5a13-46fa-8b6e-81febd5e1737',
			'c4b397d2-a63d-4bbe-9a56-d7f9a09f4208',
		].includes(this.checkoutKey!);
	}

	get isEcg() {
		return [
			'8ae0bcbe-7d49-4932-a59a-58e5365d540a',
		].includes(this.checkoutKey!);
	}

	get isSportsEvents365(): boolean {
		return [
			'b70c3112-43fd-41bb-a13a-0be6a6a93997',
			'2291a115-defd-42f0-824c-283a196bd0f8',
			'448b55cc-2b18-4a1a-aed8-3de3ebe87342',
			'51b76fe4-a415-4060-b543-7024fecef80e',
		].includes(this.checkoutKey!);
	}

	get isFundedPeaks() {
		return [
			'8a33d121-a9dc-42a1-88c8-9abf3a58342c',
		].includes(this.checkoutKey!);
	}

	get isMilliyfx() {
		return [
			'f9778b2b-a65e-4949-8f96-d502db1ea309',
		].includes(this.checkoutKey!);
	}

	get isKroxio() {
		return [
			'debb25f8-46e6-463c-8533-f95ec3c78c28',
		].includes(this.checkoutKey!);
	}

	get isFxTampa() {
		return [
			'd22affe2-0e3a-42b6-91b5-77088542b0f1',
		].includes(this.checkoutKey!);
	}

	get isSolisMarkets() {
		return [
			'2d5ebf81-55c4-4a5b-9b39-748330db5a3c',
			'c14f8b32-4c63-4a36-85b5-24fa2fa6fd97',
			'bcc4722e-9494-4605-a77f-34c226a05208',
		].includes(this.checkoutKey!);
	}

	get isDominionMarkets() {
		return [
			'1aa3f93b-1058-4f59-a381-7f141cf90e8a',
		].includes(this.checkoutKey!);
	}

	get isMarketsCom() {
		return [
			'778d735b-7529-4b61-add8-c3e5d924ab52',
			'2993298f-398a-4529-a01c-a14f3eb439f6',
		].includes(this.checkoutKey!);
	}

	get isGo4rex() {
		return [
			'a1d5aadc-2e3d-41fe-9172-e1b56558eee0',
		].includes(this.checkoutKey!);
	}

	get isOriginFx() {
		return [
			'c15e39b4-f618-4916-a0fd-693405cccbc5',
		].includes(this.checkoutKey!);
	}

	get isSfxGlobal() {
		return [
			'311849bb-630b-4236-948b-f314e75dad70',
		].includes(this.checkoutKey!);
	}

	get is77Markets() {
		return [
			'426ef8cb-18d6-4fa0-b4b3-aff39bd6e6ba',
			'd424f56e-5797-4208-941b-7f1e72edbff0',
		].includes(this.checkoutKey!);
	}

	get isTradeGlobal() {
		return [
			'71919690-7448-4a58-8051-5b445bb1b130',
			'9e16e86e-03e9-49bf-86be-a1d2550f5f87',
		].includes(this.checkoutKey!);
	}

	get isRazeMarkets() {
		return [
			'068e3028-3e63-4b67-bfd4-94a126224e09',
		].includes(this.checkoutKey!);
	}

	get isFundedPro() {
		return [
			'8112be12-48b7-47f1-81a8-b2f5cfe9737d',
		].includes(this.checkoutKey!);
	}

	get isFundYourFx() {
		return [
			'dc32ab05-1ddb-427e-8f6f-7f5e6c930012',
		].includes(this.checkoutKey!);
	}

	constructor(public dto: EmbeddedDataDTO) {
		this.dto = dto = mapKeys(dto, (value, key) => camelCase(key));

		if (dto.country?.toLowerCase() === 'all')
			throw new Error(`Country ${ dto.country } is not supported`);

		this.country = Countries.findByCodeString(<any>dto.country)!;

		this.stateCode = new ValidatableProperty<string>([
			CheckoutValidators.stateByCode(this.country),
		]);

		this.zipCode = new ValidatableProperty([
			Validators.required,
			CheckoutValidators.countryZipCode(this.country),
		]);

		chain(dto)
			.mapKeys(this.__keysRemapper)
			.mapValues(this.__arrayFromCommaStringMapper)
			.mapValues(this.__snakeCaseValueMapper)
			.mapValues(this.__paymentOptionTypeMapper)
			.mapValues(this.__uppercaseMapper)
			.mapValues(this.__lowercaseMapper)
			.mapValues(this.__trimValueMapper)
			.mapValues(this.__phoneMapper)
			.mapValues(this.__objectMapper)
			.mapValues(this.__themeMapper)
			.mapValues(this.__creditCardMapper)
			.mapValues(this.__backUrlDecodeMapper)
			.mapValues(this.__paymentTokenQueryParamDecodeMapper)
			.mapValues(this.__booleanMapper)
			.forOwn((value: string, key: number | string) => {
				const propertyValue = (<any> this)[key];

				if ((isString(value) || isNumber(value)) && propertyValue instanceof ValidatableProperty)
					propertyValue.set(value);
				else if (!isNil(value))
					(<any> this)[key] = value;
			});

		this.language = dto.language ? CheckoutLanguages.findByIso(dto.language) : null;

		if (this.currencyCode)
			this.currency = new FiatCurrency(this.currencyCode);

		if (this.cardHolderName.valid) {
			if (environment.name !== 'staging' && Validators.cardHolder({ value: this.cardHolderName.value }))
				this.cardHolderName.set('');
		} else if (!Validators.cardHolder({ value: this.fullName }))
			this.cardHolderName.set(this.fullName!);

		this.isDemostand = !!this.presetId;

		if (this.checkoutKey)
			this.cashierKey = this.checkoutKey;

		if (this.cashierKey)
			this.checkoutKey = this.cashierKey;

		this.cashierToken = this.checkoutSessionId = dto.cashierToken
			?? dto.checkoutToken
			?? dto.cashierSessionId
			?? dto.checkoutSessionId
			?? dto.sessionId
			?? dto.cid
			?? dto.token!;

		this.showRedirectMessageOnFinalStatusPage ||= this.showRedirectMessage;

		if (this.hidePaymentMethods?.includes(PaymentOptionType.apm))
			this.hidePaymentMethods.push(PaymentOptionType.banks);

		if (!dto.theme)
			this.theme = CheckoutTheme.light;

		this.__setPhoneCountryDialCode();
	}

	private __setPhoneCountryDialCode() {
		if (this.phone.value && this.phone.valid) {
			try {
				const phoneNumber = parsePhoneNumber(this.phone.value);

				this.phoneCountryDialCode = `+${ phoneNumber.countryCallingCode }`;
			} catch { }
		}

		this.phoneCountryDialCode ||= this.country?.dialCodes![0].internationalDialCodePrefix;
	}

	private readonly __paymentOptionTypeMapper = (value: string, key: string) => {
		const shouldBeParsed = [
			'directPaymentMethod',
			'singlePaymentMethod',
			'hidePaymentMethods',
		].includes(key);

		if (!shouldBeParsed)
			return value;

		if (isEmpty(value))
			return;

		return isArray(value)
			? value.map(v => PaymentOptionType.parseStrict(v))
			: PaymentOptionType.parseStrict(value);
	};

	private readonly __snakeCaseValueMapper = (value: string, key: string) => {
		const shouldBeSnaked = [
			'paymentProvider',
			'singlePaymentProvider',
			'hidePaymentProviders',
		].includes(key);

		if (!shouldBeSnaked)
			return value;

		return isArray(value) ? value.map(v => this.__toSnakeCase(v)) : this.__toSnakeCase(value);
	};

	private readonly __keysRemapper = (value: string, key: string) => {
		switch (key) {
			case 'country':
				return 'countryCode';

			case 'state':
				return 'stateCode';

			case 'currency':
				return 'currencyCode';

			// TODO remove deposit request and deposit token after deployment to prod
			// they are here just for backward compatibility for a short period of time
			case 'depositRequest':

			case 'depositToken':
				return 'paymentToken';

			case 'useLocalApi':
				return 'useLocalBackend';

			case 'preventOverrideByCashierSession':
				return 'preventOverrideBySession';

			default:
				return key;
		}
	};

	private readonly __arrayFromCommaStringMapper = (value: string | null, key: string) => [ 'hidePaymentProviders', 'hidePaymentMethods' ].includes(key)
		? value?.split(',').map(v => v.trim())
		: value;

	private readonly __phoneMapper = (value: string | null, key: string) => {
		if (key !== 'phone' || !value)
			return value;

		return value.startsWith('+') ? value : `+${ value }`;
	};

	private readonly __trimValueMapper = (value: string | null, key: string) => [ 'firstName', 'lastName', 'email', 'phone' ].includes(key)
		? value?.trim()
		: value;

	private readonly __creditCardMapper = (value: string | null, key: string) => key === 'creditCard' && value
		? JSON.parse(value)
		: value;

	private readonly __backUrlDecodeMapper = (value: string | null, key: string) => key === 'backUrl' && value
		? decodeURIComponent(value)
		: value;

	private readonly __paymentTokenQueryParamDecodeMapper = (value: string | null, key: string) => key === 'paymentToken' && value
		? decodeURIComponent(value)
		: value;

	private readonly __themeMapper = (value: CheckoutTheme | string | null, key: string) => key === 'theme' || key === 'initialTheme'
		? CheckoutTheme.parse(value) ?? CheckoutTheme.light
		: value;

	private readonly __uppercaseMapper = (value: string | null, key: string) => [ 'countryCode', 'currencyCode' ].includes(key)
		? value?.toUpperCase()
		: value;

	private readonly __lowercaseMapper = (value: string | null, key: string) => [ 'theme' ].includes(key)
		? value?.toLowerCase()
		: value;

	private readonly __objectMapper = (value: string | null, key: string) => {
		if (key !== 'alwaysVisibleInputsForProviders' || !isString(value))
			return value;

		return mapKeys(
			JSON.parse(decodeURIComponent(value)),
			(embeddedObjectValue, embeddedObjectKey) => camelCase(embeddedObjectKey),
		);

	};

	private readonly __booleanMapper = (value: boolean | string | null, key: string) => {
		// Default true
		if ([
			'hideCardHolderNameWhenFullNameIsAvailable',
		].includes(key))
			return !(value?.toString().toLowerCase() === 'false');

		// Default false
		if ([
			'currencyLock',
			'amountLock',
			'debug',
			'useLocalBackend',
			'useMockBackend',
			'showRedirectMessageOnFinalStatusPage',
			'showProcessingMessageOnFinalStatusPage',
			'showRedirectMessage',
			'onlyCreditCardPaymentMethod',
			'hideUi',
			'preventOverrideBySession',
			'hideLanguagesDropdown',
			'dontSkipSinglePaymentBox',
			'disableProcessingMessages',
			'validateInputsOnFocusOut',
			'tickSaveCreditCardCheckboxByDefault',
			'hideSaveCreditCardCheckbox',
			'payMode',
			'enableFilterByCardBrand',
			'showPlaceholderForCardNumber',
			'showPlaceholderForCvv',
			'dontRenderCardHolderNameInUppercase',
			'showPspPaymentOptionsAsDropdown',
			'isMerchantAdmin',
		].includes(key))
			return value?.toString().toLowerCase() === 'true';

		return value;
	};

	private __toSnakeCase(value: string) {
		const snakeCaseValue = snakeCase(value);

		// TODO #CH-1124 use camelCase to avoid newtonsoft json bad serialization
		if (snakeCaseValue.includes('transact_365'))
			return snakeCaseValue.replace('transact_365', 'transact365');

		if (snakeCaseValue.includes('_t_365'))
			return snakeCaseValue.replace('_t_365', '_t365');

		if (snakeCaseValue === 'directa_24')
			return 'directa24';

		if (snakeCaseValue === 'b_2_bin_pay')
			return 'b2_bin_pay';

		if (snakeCaseValue === 'b_2_bin_pay_apm')
			return 'b2_bin_pay_apm';

		if (snakeCaseValue === 'help_2_pay')
			return 'help2_pay';

		return snakeCaseValue;
	}
}
